<template>
  <div>
    <el-autocomplete
      v-model="city_value"
      suffix-icon="el-icon-search"
      :placeholder="placeTitle"
      :size="size"
      :fetch-suggestions="queryCity"
      :disabled="!countryId || disabled"
      :clearable="clearable"
    ></el-autocomplete>
  </div>
</template>

<script>
import { getCityListByCountryId } from "~/baseUtils/AddressConfig";
export default {
  props: {
    placeTitle: {
      type: String,
      default: "City",
    },
    value: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
    countryId: {
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: "en",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.city_value = this.value;
    this.getCityList();
  },
  data() {
    return {
      city_value: null,
      cityList: [],
    };
  },
  methods: {
    queryCity(queryString, cb) {
      if (this.cityList.length) {
        let list = this.cityList.filter((item) => {
          return (
            item.value
              .toLowerCase()
              .replace(/\s/g, "")
              .indexOf(queryString.toLowerCase().replace(/\s/g, "")) === 0
          );
        });
        cb(list);
      } else {
        cb([]);
      }
    },
    async getCityList() {
      if (!this.countryId) {
        this.cityList = [];
        return;
      }
      let _locale = this.locale || this.LOCALE;
      this.cityList = getCityListByCountryId(this.countryId, _locale).map(
        (item) => {
          return { value: item["name_" + _locale] };
        }
      );
    },
  },
  watch: {
    countryId(nv) {
      if (nv) {
        this.city_value = "";
        this.getCityList();
      }
    },
    value(nv) {
      this.city_value = nv;
    },
    city_value(nv) {
      this.$emit("input", nv);
    },
  },
};
</script>

<style scoped lang="less">
.el-autocomplete {
  width: 100%;
  .el-input__icon {
    color: #c0c4cc;
  }
}
</style>