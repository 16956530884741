<template>
<el-tabs v-model="activeName" class="companyinfo" @tab-click="handleClick">
  <span class="tips" v-if="hasSavePower">企业信息待认证</span>
  <el-button type="primary" size="small" class="backbtn" @click="goback">返回列表</el-button>
  <el-tab-pane label="基本" name="first">
    <div class="content_info">
      <div class="info">
        <div class="fl contentLeft">
          <el-form
            label-width="120px"
            :model="edit_Company_Info"
            ref="edit_Company_Info"
            :rules="rules"
            :hide-required-asterisk="true"
          >
            <div class="item clearfix">
              <div class="key">企业名称：</div>
              <div class="value">
                <p v-if="isExamine">{{ company_Info.name_zh | textFormat }}</p>
                <el-form-item v-else prop="name_zh">
                  <el-input
                    :disabled="!hasSavePower"
                    v-model="edit_Company_Info.name_zh"
                    class="input"
                    size="small"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="item clearfix">
              <div class="itemInner clearfix left">
                <div class="key">国家：</div>
                <div class="value">
                  <p v-if="isExamine">
                    {{ company_Info.country | countryFormat("zh") }}
                  </p>
                  <el-form-item v-else prop="country">
                    <CountrySelect
                      :disabled="!hasSavePower"
                      v-model="edit_Company_Info.country"
                      :locale="locale"
                      v-if="locale == 'zh' || PJID == 'YMP'"
                      placeTitle
                      size="small"
                    ></CountrySelect>
                  </el-form-item>
                </div>
              </div>
              <div class="itemInner clearfix right">
                <div class="key">区域：</div>
                <div class="value">
                  <p v-if="isExamine">
                    {{ company_Info.region_zh | textFormat }}
                  </p>
                  <el-form-item v-else>
                    <CityComplete
                      :disabled="!hasSavePower"
                      v-model="edit_Company_Info.region_zh"
                      size="medium"
                      locale="zh"
                      :countryId="edit_Company_Info.country"
                      placeTitle
                    ></CityComplete>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="item clearfix" v-if="PJID !== 'YMP'">
              <div class="itemInner clearfix left">
                <div class="key">注册资金：</div>
                <div class="value">
                  <p v-if="isExamine">
                    {{ company_Info.registered_capital | textFormat }}
                  </p>
                  <el-form-item v-else prop="registered_capital">
                    <el-input
                      :disabled="!hasSavePower"
                      v-model="edit_Company_Info.registered_capital"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="itemInner clearfix right">
                <div class="key">企业规模：</div>
                <div class="value">
                  <p v-if="isExamine">
                    {{ company_Info.scale | staffsizeFormat("zh") }}
                  </p>
                  <el-form-item v-else prop="scale">
                    <el-select
                      :disabled="!hasSavePower"
                      style="width: 100%"
                      filterable
                      v-model="edit_Company_Info.scale"
                      placeholder=" "
                      size="small"
                    >
                      <el-option
                        v-for="item in staffsizeList"
                        :key="item.value"
                        :label="item.label_zh"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="item clearfix" v-if="PJID !== 'YMP'">
              <div class="itemInner clearfix left">
                <div class="key">注册年份：</div>
                <div class="value">
                  <p v-if="isExamine">
                    {{ company_Info.found_year | textFormat }}
                  </p>
                  <el-form-item v-else prop="found_year">
                    <el-date-picker
                      :disabled="!hasSavePower"
                      size="small"
                      style="width: 100%"
                      v-model="edit_Company_Info.found_year"
                      type="year"
                      placeholder=" "
                      :picker-options="pickerOptions"
                      @input="yearChange"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="itemInner clearfix right">
                <div class="key">企业官网：</div>
                <div class="value">
                  <p v-if="isExamine">{{ company_Info.website | textFormat }}</p>
                  <el-form-item v-else>
                    <el-input
                      :disabled="!hasSavePower"
                      v-model="edit_Company_Info.website"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="item clearfix">
              <div class="itemInner clearfix left">
                <div class="key">联系人：</div>
                <div class="value">
                  <p v-if="isExamine">{{ company_Info.contacts | textFormat }}</p>
                  <el-form-item v-else prop="contacts">
                    <el-input
                      :disabled="!hasSavePower"
                      v-model="edit_Company_Info.contacts"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="itemInner clearfix right">
                <div class="key">邮箱：</div>
                <div class="value">
                  <p v-if="isExamine">{{ company_Info.email | textFormat }}</p>
                  <el-form-item v-else prop="email">
                    <el-input
                      :disabled="!hasSavePower"
                      v-model="edit_Company_Info.email"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="item clearfix">
              <div class="itemInner clearfix left">
                <div class="key">联系电话：</div>
                <div class="value">
                  <p v-if="isExamine">
                    <span>{{
                      company_Info.telephone | phoneFormat(company_Info.area_code)
                    }}</span>
                  </p>
                  <el-form-item v-else prop="telephone">
                    <el-input
                      :disabled="!hasSavePower"
                      v-model="edit_Company_Info.telephone"
                      size="small"
                    >
                      <el-select
                        :disabled="!hasSavePower"
                        style="width: 90px"
                        v-model="edit_Company_Info.area_code"
                        slot="prepend"
                        class="companyTelephoneList"
                        placeholder="请选择"
                        popper-class="registerAreaCodeList"
                        filterable
                        :filter-method="areaCodeFilter"
                      >
                        <el-option
                          v-for="(item, index) in areaCodeList"
                          :key="item.area_code + index + ''"
                          :value="item.area_code"
                          :title="item.country | areacodeTitleFormat('zh')"
                        >
                          <p class="area_code">{{ item.area_code }}</p>
                          <p class="country textOverflow">
                            {{ item.country | areacodeAddressFormat("zh") }}
                          </p>
                        </el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="itemInner clearfix right">
                <div class="key">传真：</div>
                <div class="value">
                  <p v-if="isExamine">{{ company_Info.fax | textFormat }}</p>
                  <el-form-item v-else prop="fax">
                    <el-input
                      :disabled="!hasSavePower"
                      v-model="edit_Company_Info.fax"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="item clearfix" v-if="PJID === 'YMP'">
              <div class="itemInner clearfix left">
                <div class="key">企业官网：</div>
                <div class="value">
                  <p v-if="isExamine">{{ company_Info.website | textFormat }}</p>
                  <el-form-item v-else>
                    <el-input
                      :disabled="!hasSavePower"
                      v-model="edit_Company_Info.website"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="item clearfix">
              <div class="key">企业地址：</div>
              <div class="value">
                <p v-if="isExamine">{{ company_Info.address_zh | textFormat }}</p>
                <el-form-item v-else>
                  <el-input
                    :disabled="!hasSavePower"
                    v-model="edit_Company_Info.address_zh"
                    size="small"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="item clearfix noHidden">
              <div class="key">企业简介：</div>
              <div class="value">
                <div v-show="isExamine" class="breakAll">
                  <div
                    v-if="edit_Company_Info.profile_zh"
                    v-html="edit_Company_Info.profile_zh"
                  ></div>
                  <div v-else>-</div>
                </div>
                <el-form-item
                  ><div v-show="!isExamine">
                    <!-- <umEditor
                    ref="umEditor"
                    :styleConfig="{ width: '606px', height: '170px' }"
                  ></umEditor>-->
                    <el-input
                      :disabled="!hasSavePower"
                      type="textarea"
                      style="height: 170px"
                      :maxlength="maxlength"
                      show-word-limit
                      v-model="edit_Company_Info.profile_zh"
                    ></el-input></div
                ></el-form-item>
              </div>
            </div>
            <div class="footer-btn">
              <el-button
                v-if="hasSavePower"
                type="primary"
                size="small"
                style="padding: 9px 25px"
                :disabled="
                  edit_Company_Info.profile_zh &&
                  edit_Company_Info.profile_zh.length > 600
                "
                @click="confirm"
                :loading="saveLoading"
                >更新数据</el-button
              >
            </div>
          </el-form>
        </div>
        <div class="fl contentLine"></div>
        <div class="fl contentRight">
          <div class="clearfix avatarInfo">
            <span class="key">企业logo</span>
            <span class="value">
              <div class="name_input">
                <div class="imgDiv" v-if="hasSavePower">
                  <div class="markImg tc">
                    <fileUpload
                      @uploadCompleteOSS="uploadimg"
                      :needCropImg="fileUploadOptions.needCropImg"
                      :aspectRatio="fileUploadOptions.aspectRatio"
                      :accept="fileUploadOptions.accept"
                      :company_id="company_Info.id"
                    >
                      <span>修改logo</span>
                    </fileUpload>
                  </div>
                  <el-image
                    class="avatar"
                    fit="fit"
                    :src="
                      edit_Company_Info.logo
                        ? edit_Company_Info.logo
                        : DEFAULT_LOGO
                    "
                  ></el-image>
                </div>
                <div class="imgDiv" v-else>
                  <el-image
                    class="avatar"
                    fit="fit"
                    :preview-src-list="srcList"
                    :src="
                      edit_Company_Info.logo
                        ? edit_Company_Info.logo
                        : DEFAULT_LOGO
                    "
                  ></el-image>
                </div>
              </div>
            </span>
          </div>
          <certificate
            ref="certificate"
            :generationBinding="true"
            :disbaled="hasSavePower"
            :bind_company_id="bind_company_id"
            @uploadCert="uploadCertImg"
          ></certificate>
        </div>
      </div>

      <el-dialog
        title="补充公司信息"
        :visible.sync="addCompanyInfoDialog"
        append-to-body
      >
        <addCompanyInfo
          v-if="addCompanyInfoDialog"
          :companyFileAndInvoiceNum="companyFileAndInvoiceNum"
          :generationBinding="true" 
          :bind_company_id="bind_company_id"
          @upOK="upOK"
          @handleClose="handleClose"
        ></addCompanyInfo>
      </el-dialog>
    </div>
  </el-tab-pane>
  <el-tab-pane label="开票" name="second">
    <invoice ref="invoiceRef" :generationBinding="true" :bind_company_id="bind_company_id"></invoice>
  </el-tab-pane>
</el-tabs>
</template>
<script>
import CountrySelect from "~/baseComponents/CountrySelect";
import CityComplete from "~/baseComponents/CityComplete";
import fileUpload from "~/baseComponents/fileUpload";
import certificate from "~bac/components/company/basic/certificate";
import invoice from "~bac/views/company/invoice";
import { areaCodeList } from "~/baseUtils/AddressConfig";
import umEditor from "@/baseComponents/umEditor";
import { staffsizeList } from "~scr/utils/enumValue"; //企业规模
import addCompanyInfo from "~ymp/components/bookingSpace/addCompanyInfo"; // 玉漭
export default {
  components: {
    fileUpload,
    CountrySelect,
    CityComplete,
    umEditor,
    certificate,
    invoice,
    addCompanyInfo,
  },
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    locale: {
      type: String,
      default: "",
    },
  },
  watch: {
    info() {
      this.company_Info = this.info;
      this.edit_Company_Info = Object.assign(this.edit_Company_Info, this.info);
      this.$refs.certificate.getCertificateStore();
    },
  },
  data() {
    return {
      activeName:'first',
      fileUploadOptions: {
        needCropImg: true,
        aspectRatio: 20 / 20,
        accept: ".jpg,.jpeg,.bmp,.png",
      },
      srcList: [],
      maxlength: 600,
      areaCodeList,
      staffsizeList,
      areaCodeListCopy: [].concat(areaCodeList),
      isExamine: false,
      edit_Company_Info: {
        area_code: "+86",
      },
      rules:{
        name_zh: [ { required: true,  message: " ",trigger: "blur",},],
        country: [ { required: true,  message: " ",trigger: "blur",},],
        contacts: [ { required: false,  message: " ",trigger: "blur",},],
        telephone:[
          { required: false, message: " ", trigger: "blur" },
          { validator: this.isMobileNumber, trigger: "blur" }
        ],
        fax:[
          { required: false, message: " ", trigger: "blur" },
          { validator: this.isTelOrFax, trigger: "blur" }
        ],
        email:[
          { required: false, message: " ", trigger: "blur" },
          { validator: this.isEmail, trigger: "blur" }
        ]
      },
      company_Info: {},
      uploadCert: {},
      saveLoading: false,
      pickerOptions: {
        disabledDate: (time) => {
          return (
            parseInt(new Date(time).getFullYear()) > new Date().getFullYear()
          );
        },
      },
      avatarImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/default-logo.jpg",
      addCompanyInfoDialog:false,
      companyFileAndInvoiceNum:3,
      bind_company_id:'',
      company_status:''
    };
  },
  methods: {
    handleClick(tab, event){
      if(this.activeName == 'second'){
        this.$refs.invoiceRef.getList();
      }
    },
    isTelOrFax(rule, value, callback) {
      if (!value) {
          callback();
        } else {
        const reg = /^(\d{3,4}-)?\d{5,8}$/;
        const isTel = reg.test(value);
        if (value.length < 7 || value.length > 13 || !isTel ) {
          callback(new Error("传真格式如: 021-1234567"));
        } else {
          callback();
        }
      }
    },
    isMobileNumber(rule, value, callback) {
      if (!value) {
        return new Error("请输入电话号码");
        } else {
          const reg = /^[1]+[0-9]{10}$/;
          const isPhone = reg.test(value);
          value = Number(value); //转换为数字
          if (typeof value === "number" && !isNaN(value)) {//判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) { //判断是否为11位手机号
          callback(new Error("手机号码格式如:134xxxx8888"));
          } else {
          callback();
          }
          } else {
          callback(new Error("请输入电话号码"));
        }
      }
    },
    isEmail (rule, value, callback) {
      if (!value) {
          callback();
        } else {
          const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
          const email = reg.test(value);
        if (!email) {
          callback(new Error("邮箱格式如: admin@163.com"));
        } else {
          callback();
        }
      }
    },
    goback(){
      this.$router.back()
    },
    // 玉漭 公司开票信息 组件函数
    handleClose(e){
      console.log('handleClose',e);
      this.addCompanyInfoDialog=false
    },
    upOK(e){
      console.log('upOK',e);
      this.addCompanyInfoDialog=false
    },
    async getList() {
      if (this.$route.query.company_id) {
        let companyInfo = await this.$store.dispatch(
          "baseConsole/company_getCompanyInfo",
          {
            company_id: this.bind_company_id,
            is_self: true,
          }
        );
        if (companyInfo.success) {
          this.edit_Company_Info = companyInfo.data;
          if (this.edit_Company_Info.found_year) {
            this.edit_Company_Info.found_year =
              this.edit_Company_Info.found_year + "";
          } else {
            this.edit_Company_Info.found_year = "";
          }
          if (this.edit_Company_Info.profile_zh) {
            this.edit_Company_Info.profile_zh =
              this.edit_Company_Info.profile_zh
                .replace(/<[^>]+>/g, "")
                .replace(/&nbsp;/gi, "");
          }
          if (this.edit_Company_Info.logo) {
            this.srcList.push(this.edit_Company_Info.logo);
          } else {
            this.srcList.push(this.DEFAULT_LOGO);
          }
        }
      }
    },
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    uploadimg(result) {
      this.edit_Company_Info.logo = result.url;
      this.$forceUpdate();
      // this.edit_Company_Info = {
      //   logo: result.url,
      // };
    },
    uploadCertImg(url, data) {
      this.uploadCert.url = url;
      this.uploadCert.certificate_id = data.length > 0 && data[0].id;
    },
    async confirm() {
      this.saveLoading = true;
      // this.edit_Company_Info.profile_zh = this.$refs.umEditor.getContent();
      let params = Object.assign({}, this.edit_Company_Info);
      delete params.id;
      delete params.created_time;
      delete params.updated_time;
      delete params.is_show;
      delete params.city;
      delete params.status;
      delete params.industry;
      delete params.region_en;
      delete params.reason;
      // params._NOCLEAR = true;
      params.company_id = this.bind_company_id;
      if (params.found_year)
        params.found_year = this.$moment(params.found_year).format("YYYY");
      // this.$log({
      //   type: 1,
      //   topic: "console.company",
      //   id: "console.company.basic.modify",
      //   content: JSON.stringify(params),
      // });
      let uploadParams = {
        company_id: this.bind_company_id,
        url: this.uploadCert.url,
        certificate_type: 0,
      };
      uploadParams.certificate_id = this.uploadCert.id;

      try {
        let result = await Promise.all([
          this.$store.dispatch("baseConsole/company_saveCompanyInfo", params),
          this.uploadCert.url
            ? this.$store.dispatch(
                "baseConsole/company_uploadQualification",
                uploadParams
              )
            : "",
        ]);
        if (result[0].success) {
          let _params = Object.assign({}, this.edit_Company_Info);
          _params.found_year = this.$moment(_params.found_year).format("YYYY");
          this.company_Info = _params;
          this.$ssTip();
          let data = await this.$store.dispatch(
            "baseStore/baseSign_getUserInfo",
            {
              user_id: this.USER_INFO.id,
            }
          );
          if (data.success) {
          }
          // this.isExamine = true;
        }else if (this.PJID == "YMP" && result[0].errorcode == 1000) {
          this.saveLoading = false;
          this.$message.warning("公司名称重复");
          return false;
        }else{
          this.$seTip()
        } 
        if (result[1].success) {
          // this.$refs.certificate.getCompanyAptitude();
        }
        this.saveLoading = false;
        if (this.PJID == "YMP") {
          this.getCompanyFileAndInvoice();
        }
      } catch (e) {
        this.saveLoading = false;
        this.$seTip();
      }
    },
    yearChange(time) {
      this.$forceUpdate();
    },
    // 判断有没有开票信息和有没有证书
    async getCompanyFileAndInvoice() {
      console.log("111");
      if (this.USER_INFO.company_bind_status == 0) return;
      let params = {
        company_id: this.bind_company_id,
      };
      let res = await this.$store.dispatch(
        "API_bookingSpace/getCompanyFileAndInvoice",
        params
      );
      console.log("222");
      if (res.success) {
        console.log("aaaaaaa", res);
        let data = res.data;
        if (
          JSON.stringify(data.invoice_data) == "{}" &&
          JSON.stringify(data.qualification_file) == "{}"
        ) {
          // this.$message.warning("订舱前请先上传公司证件和开票信息");
          this.companyFileAndInvoiceNum = 3;
          this.addCompanyInfoDialog = true;
          return;
        }
        if (JSON.stringify(data.qualification_file) == "{}") {
          // this.$message.warning("订舱前请先上传公司证件");
          this.companyFileAndInvoiceNum = 1;
          this.addCompanyInfoDialog = true;
        }
        if (JSON.stringify(data.invoice_data) == "{}") {
          // this.$message.warning("订舱前请先上传开票信息");
          this.companyFileAndInvoiceNum = 2;
          this.addCompanyInfoDialog = true;
        }
        if (
          JSON.stringify(data.invoice_data) != "{}" &&
          JSON.stringify(data.qualification_file) != "{}"
        ) {
          this.companyFileAndInvoiceNum = 4;
          this.addCompanyInfoDialog = false;
        }
      }
    },
  },
  // computed: {
  //   company_Info() {
  //     try {
  //       return Object.assign({}, _companyInfo);
  //     } catch (e) {}
  //   },
  // },
  computed: {
    hasSavePower: function () {
      // return this.inspectButton("cos:coi:mdi");
      return this.company_status == 2 ? false:true;
    },
  },
  mounted() {
    this.bind_company_id = this.$route.query.company_id
    this.company_status = this.$route.query.company_status
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.content_info {
  // margin: 0 auto;
  .contentLeft {
    padding: 10px 0 5px 12px;
    width: 750px;
    .item {
      .itemInner {
        float: left;
        // .value {
        //   width: 400px;
        // }
        &.left {
          width: 50%;
        }
        &.right {
          width: 50%;
          //   .key {
          //     width: 80px;
          //   }
        }
      }
    }
    .key {
      width: 120px;
      float: left;
      color: #808080;
      text-align: right;
      padding-right: 10px;
      line-height: 35px;
    }
  }
  .contentLine {
    height: 300px;
    width: 1px;
    background: #dcdfe6;
    margin: 120px 30px 0;
  }
  .contentRight {
    width: 250px;
    min-height: 700px;
    .avatarInfo {
      margin-bottom: 50px;
      margin-top: 20px;
    }
    .imgDiv {
      position: relative;
      width: 240px;
      height: 240px;
      margin-top: 16px;
      background: #ececec;
      .markImg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        line-height: 240px;
        transition: 0.5s;
        span {
          color: #fff;
          display: none;
        }
      }
      .avatar {
        width: 100%;
      }
      &:hover {
        .markImg {
          background-color: rgba(0, 0, 0, 0.5);
          span {
            display: block;
          }
        }
      }
    }
    .key {
      color: #808080;
    }
  }
  .footer-btn {
    padding: 10px 0 20px 120px;
  }
  // /deep/.el-select {
  //   width: 100%;
  // }
}
.info {
  width: 1070px;
}
/deep/ .editor_container  {
  padding-top: 10px;
  .edui-btn-toolbar {
    height: 0px;
  }
  .edui-toolbar  {
    display: none;
  }
   .edui-editor-body  {
    padding-top: 2px;
  }
   p  {
    line-height: 20px;
    margin: 0px;
    color: #666;
  }
  .edui-body-container  {
    width: 606px !important;
    height: 160px !important;
    overflow-x: hidden !important;
    padding: 0 5px;
    text-align: justify;
  }
  .edui-container  {
    box-shadow: none;
    border: 1px solid #dcdfe6;
  }
}
/deep/.el-textarea__inner {
  height: 170px;
}
/deep/ .el-tabs__header{
  background: #f2f2f2;
}
.companyinfo{
  background: #fff;
  position: relative;
  .tips{
    position: absolute;
    right: 160px;
    top: 17px;
    color: red;
  }
  .backbtn{
    position: absolute;
    right: 20px;
    top: 10px;
  }
}
</style>